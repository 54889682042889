.player-background {
  background: linear-gradient(
      180deg,
      rgba(254, 234, 131, 0) 0%,
      rgba(254, 234, 131, 0.1) 54.69%,
      rgba(0, 0, 0, 0) 100%,
      rgba(254, 234, 131, 0) 100%
    ),
    linear-gradient(
      355.67deg,
      #f63523 -18.37%,
      #7d0b00 -18.36%,
      #55006e 100.08%
    );
  box-shadow: 0px -20px 24px rgba(0, 0, 0, 0.25);
  height: 60px;
  padding: 5px 10px;
}

.play-button {
  height: 40px;
  width: 40px;
  background: linear-gradient(180deg, #6dfe95 0%, #1cf9d7 100%);
}

.now-playing-artwork {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.fullscreen-player {
  background-color: black;
  pointer-events: auto;
}

.video-canvas {
  -webkit-mask-image: linear-gradient(to bottom, black 40%, transparent 87%);
  mask-image: linear-gradient(to bottom, black 40%, transparent 87%);
}

.image-fade {
  -webkit-mask-image: linear-gradient(to bottom, black 74%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 74%, transparent 100%);
}

.fs-player-nav {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 70%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.fs-controls-bkg {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.4) 70%,
    rgba(0, 0, 0, 0) 100%
  );
}
