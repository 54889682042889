.chrome {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
  pointer-events: none;
}

.top-navigation {
  pointer-events: auto;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 70%,
    rgba(0, 0, 0, 0.7) 100%
  );

  opacity: 1;
  transform: translateY(0);
  transition: transform 0.4s, opacity 0.4s;
}

.top-navigation.out {
  opacity: 0;
  transform: translateY(-90px);
  transition: transform 0.4s, opacity 0.4s;
}

.nav-bar {
  height: 66px;
}

.safe-area-top-content {
  position: fixed;
  width: 100vw;
  top: 0;
  top: env(safe-area-inset-top);
  z-index: 998;
}

.safe-area-bottom-content {
  position: fixed;
  width: 100vw;
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
  z-index: 997;

  transform: translateY(0);
  transition: transform 0.4s;
}

.safe-area-bottom-content.out {
  transform: translateY(66px);
  transition: transform 0.4s;
}
