@import url("https://cdn-stg.unlocktheotherside.com/public/fonts/tos.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap");

.min-safe-h-screen {
  /* equivalent to 100dvh in major browsers */
  min-height: calc(
    100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
  );
  display: flex;
}

@supports (-webkit-touch-callout: none) {
  .min-safe-h-screen {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    min-height: -webkit-fill-available;
    display: flex;
  }
}

div#root {
  overflow-x: clip;
  overscroll-behavior: none;
  height: 100vh;
  /* position: fixed; */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  overscroll-behavior: none;
  overflow-x: clip;
  /* height: 100%; */
  overflow-y: hidden;
  /* position: fixed; */
  /* background-color: #000000; */
  background: linear-gradient(
      191.62deg,
      #3a0d46 0.03%,
      #7d1e31 42.89%,
      #7c102f 58.63%,
      #44064f 100.25%
    )
    #000000;
  /* background-repeat: no-repeat; */
  background-size: auto;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg.plan-icon path {
  fill: #fff;
  stroke: #fff;
}

@keyframes scroll {
  from {
    left: 20px;
  }
  to {
    left: -100px;
  }
}

.tos-primary-bkg {
  background: linear-gradient(180deg, #6dfe95 0%, #6dfe95 50%, #1cf9d7 100%);
}

.tos-body-bkg {
  background: linear-gradient(
    191.62deg,
    #3a0d46 0.03%,
    #7d1e31 42.89%,
    #7c102f 58.63%,
    #44064f 100.25%
  );
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.tos-treasure-bkg {
  background: linear-gradient(
    191.62deg,
    #de1c6e 0.03%,
    #fa2860 42.89%,
    #f83260 58.63%,
    #e0346a 100.25%
  );
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}

.tos-about-bkg {
  background: linear-gradient(
    191.62deg,
    #3a0d46 0.03%,
    #7d1e31 42.89%,
    #7c102f 58.63%,
    #44064f 100.25%
  );
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.album-play-label {
  z-index: -1;
  animation: scroll 7s linear 0s infinite;
}

.music-player-modal {
  bottom: 60px;
  transform: translateY(5px);
  transition: transform 0.4s;
}

.music-player-modal.active {
  transform: translateY(65px);
  transition: transform 0.4s;
}

.overflow-x-auto {
  cursor: grab;
}

.overflow-x-auto::-webkit-scrollbar {
  height: 0px;
}

/* Track */
.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 600px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

.swiper {
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

.swiper-container .swiper-item {
  position: relative;
}

.swiper-container .swiper-item__bg {
  background: transparent;
  box-shadow: 0 0 10px 3px #00000070;
  border-radius: 30px;
}

.swiper-container .swiper-item__bg-img {
  height: 100%;
  transition: all 1s;
  border-radius: 30px;
}

.swiper-container .swiper-slide.swiper-slide-active {
  #transition-duration: 0.5s !important;
}

.swiper-container .swiper-slide.swiper-slide-visible.swiper-slide-next {
  transform: translate3d(40px, 70px, -200px) rotateX(0deg) rotateY(0deg)
    rotateZ(10deg) scale(0.9) !important;
}

.swiper-container .swiper-slide.swiper-slide-visible.swiper-slide-prev {
  transform: translate3d(-40px, 70px, -200px) rotateX(0deg) rotateY(0deg)
    rotateZ(-10deg) scale(0.9) !important;
}

.swiper-container .swiper-slide-shadow-left,
.swiper-container .swiper-slide-shadow-right {
  background: transparent !important;
}

.swiper-container .swiper-slide.swiper-slide-visible span {
  display: none !important;
}

.swiper-container .swiper-slide.swiper-slide-active span {
  display: block !important;
  background: linear-gradient(180deg, #6dfe95 0%, #1cf9d7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: normal;
}

.game-swiper-container .swiper-slide {
  transform: scale(0.9) !important;
  transition: all 0.5s;
}

.game-swiper-container .swiper-slide.swiper-slide-active {
  #transform: scale(1.1) !important;
}

.knob {
  display: flex;
  position: relative;
}

.knob .knob-labels {
  width: calc(100% + 80px);
  margin-left: -35px;
  color: #36fbc2;
}

.knob .ticks {
  position: absolute;
}
.knob .ticks .tick {
  height: 102.4px;
  position: absolute;
  background: black;
  box-shadow: inset 0 0 5px 2px #36fbc2, 0 0 0 1px #36fbc2;
  width: 3px;
  transition: box-shadow 0.5s;
}

.knob .ticks .tick.tick--child {
  width: 2px;
  height: 90px !important;
}

.knob .ticks .tick.active {
  box-shadow: inset 0 0 5px 2px #36fbc2, 0 0 0 1px #36fbc2;
}
.knob.outer {
  border-radius: 50%;
  border: 1px solid #222;
  border-bottom: 5px solid #222;
  background-image: radial-gradient(100% 70%, #666 6%, #333 90%);
  box-shadow: 0 5px 15px 2px black, 0 0 5px 3px black, 0 0 0 12px #444;
}
.knob.inner {
  border-radius: 50%;
}
.knob.inner .grip {
  position: absolute;
  width: 5%;
  height: 5%;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background: #509eec;
  box-shadow: 0 0 3px 1px black;
}

.overflow-x-auto {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.mix-blend-difference {
  mix-blend-mode: difference;
}

.mix-blend-normal {
  mix-blend-mode: normal;
}

.mix-blend-overlay {
  mix-blend-mode: overlay;
}

.js-music-accordion
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  /* background: rgba(0, 0, 0, 0.4); */
}

/* Listen Icon Animation
.swiper-slide-active #eqEhTKjDC5H3_to {animation: eqEhTKjDC5H3_to__to 4000ms linear infinite normal forwards}
.swiper-slide-active #eqEhTKjDC5H8_to {animation: eqEhTKjDC5H8_to__to 4000ms linear infinite normal forwards}


@keyframes eqEhTKjDC5H3_to__to { 0% {transform: translate(448.6501px,297px)} 100% {transform: translate(-450.3499px,297px)}} 
@keyframes eqEhTKjDC5H8_to__to { 0% {transform: translate(448.6501px,339.575308px)} 100% {transform: translate(-450.3499px,329.575308px)}}
*/

/* Artists Icon Animation */
.swiper-slide-active #eZDLR9vpnMW5_to {
  animation: eZDLR9vpnMW5_to__to 800ms linear infinite normal forwards;
}
.swiper-slide-active #eZDLR9vpnMW26 {
  animation: eZDLR9vpnMW26_c_o 800ms linear infinite normal forwards;
}
.swiper-slide-active #eZDLR9vpnMW27 {
  animation: eZDLR9vpnMW27_c_o 800ms linear infinite normal forwards;
}
.swiper-slide-active #eZDLR9vpnMW32_to {
  animation: eZDLR9vpnMW32_to__to 800ms linear infinite normal forwards;
}
.swiper-slide-active #eZDLR9vpnMW37_to {
  animation: eZDLR9vpnMW37_to__to 800ms linear infinite normal forwards;
}
@keyframes eZDLR9vpnMW5_to__to {
  0% {
    transform: translate(448.6501px, 297px);
  }
  100% {
    transform: translate(268.8501px, 297px);
  }
}
@keyframes eZDLR9vpnMW26_c_o {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    opacity: 1;
  }
}
@keyframes eZDLR9vpnMW27_c_o {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes eZDLR9vpnMW32_to__to {
  0% {
    transform: translate(-17.516307px, 315.546004px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: translate(84.130752px, 315.546004px);
  }
}
@keyframes eZDLR9vpnMW37_to__to {
  0% {
    transform: translate(-21.516307px, 315.546004px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: translate(80.130752px, 315.546004px);
  }
}

/* Thoughts Icon Animation
.swiper-slide-active #emJNlFQgbDh5_to {animation: emJNlFQgbDh5_to__to 7000ms linear infinite normal forwards}
.swiper-slide-active #emJNlFQgbDh35_to {animation: emJNlFQgbDh35_to__to 7000ms linear infinite normal forwards}
.swiper-slide-active #emJNlFQgbDh40_to {animation: emJNlFQgbDh40_to__to 7000ms linear infinite normal forwards}
@keyframes emJNlFQgbDh5_to__to { 0% {transform: translate(448.6501px,297px)} 57.142857% {transform: translate(-450.3499px,297px)} 100% {transform: translate(-450.3499px,297px)}} 
@keyframes emJNlFQgbDh35_to__to { 0% {transform: translate(-17.516307px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 48.571429% {transform: translate(414.483693px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 50% {transform: translate(414.483693px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 51.428571% {transform: translate(414.483693px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {transform: translate(-17.516307px,315.546004px)}} 
@keyframes emJNlFQgbDh40_to__to { 0% {transform: translate(-21.516307px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 48.571429% {transform: translate(410.483693px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 50% {transform: translate(410.483693px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 51.428571% {transform: translate(410.483693px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {transform: translate(-21.516307px,315.546004px)}}
*/

/* Play Icon Animation
.swiper-slide-active #eLMk3xdaQ2n3_to {animation: eLMk3xdaQ2n3_to__to 5000ms infinite}
.swiper-slide-active #eLMk3xdaQ2n3 {animation: eLMk3xdaQ2n3_c_o 5000ms infinite}
.swiper-slide-active #eLMk3xdaQ2n6_ts {animation: eLMk3xdaQ2n6_ts__ts 5000ms infinite}
.swiper-slide-active #eLMk3xdaQ2n6 {animation: eLMk3xdaQ2n6_c_o 5000ms infinite}

@keyframes eLMk3xdaQ2n3_to__to { 0% {transform: translate(209.999999px,-231.145569px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 20% {transform: translate(209.999999px,218.854431px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 84% {transform: translate(209.999999px,218.854431px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {transform: translate(209.999999px,-231.145569px)}} 
@keyframes eLMk3xdaQ2n3_c_o { 0% {opacity: 1;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 20% {opacity: 1;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 84% {opacity: 1;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {opacity: 0}} 
@keyframes eLMk3xdaQ2n6_ts__ts { 0% {transform: translate(209.999999px,330.992463px) scale(0.356235,0.356235)} 20% {transform: translate(209.999999px,330.992463px) scale(0.356235,0.356235);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 28% {transform: translate(209.999999px,330.992463px) scale(1.832049,1.832049);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 36% {transform: translate(209.999999px,330.992463px) scale(0.356235,0.356235);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 44% {transform: translate(209.999999px,330.992463px) scale(1.832049,1.832049);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 52% {transform: translate(209.999999px,330.992463px) scale(0.356235,0.356235);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 60% {transform: translate(209.999999px,330.992463px) scale(1.832049,1.832049);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 68% {transform: translate(209.999999px,330.992463px) scale(0.356235,0.356235);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 76% {transform: translate(209.999999px,330.992463px) scale(1.832049,1.832049);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 84% {transform: translate(209.999999px,330.992463px) scale(0.356235,0.356235)} 100% {transform: translate(209.999999px,330.992463px) scale(0.356235,0.356235)}} 
@keyframes eLMk3xdaQ2n6_c_o { 0% {opacity: 0;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 20% {opacity: 0;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 28% {opacity: 1;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 36% {opacity: 0;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 44% {opacity: 1;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 52% {opacity: 0;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 60% {opacity: 1;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 68% {opacity: 0;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 76% {opacity: 1;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 84% {opacity: 0} 100% {opacity: 0}}
*/

/* Arcade Icon Animation

.swiper-slide-active #ePIq8LZSe0t5_to {animation: ePIq8LZSe0t5_to__to 2000ms linear infinite normal forwards}
.swiper-slide-active #ePIq8LZSe0t32_to {animation: ePIq8LZSe0t32_to__to 2000ms linear infinite normal forwards}
.swiper-slide-active #ePIq8LZSe0t37_to {animation: ePIq8LZSe0t37_to__to 2000ms linear infinite normal forwards}
.swiper-slide-active #ePIq8LZSe0t46_ts {animation: ePIq8LZSe0t46_ts__ts 2000ms linear infinite normal forwards}
.swiper-slide-active #ePIq8LZSe0t46 {animation: ePIq8LZSe0t46_f_p 2000ms linear infinite normal forwards}
.swiper-slide-active #ePIq8LZSe0t48 {animation: ePIq8LZSe0t48_f_p 2000ms linear infinite normal forwards}

@keyframes ePIq8LZSe0t5_to__to { 0% {transform: translate(448.6501px,297px)} 100% {transform: translate(-0.8499px,297px)}} 
@keyframes ePIq8LZSe0t32_to__to { 0% {transform: translate(-17.516307px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {transform: translate(236.60134px,315.546004px)}} 
@keyframes ePIq8LZSe0t37_to__to { 0% {transform: translate(-21.516307px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {transform: translate(232.60134px,315.546004px)}} 
@keyframes ePIq8LZSe0t46_ts__ts { 0% {transform: translate(191.875px,357.38621px) scale(1.759032,1.759032);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 15% {transform: translate(191.875px,357.38621px) scale(1.164758,1.164758);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 20% {transform: translate(191.875px,357.38621px) scale(1.716316,1.716316);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 25% {transform: translate(191.875px,357.38621px) scale(1.349638,1.349638);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 30% {transform: translate(191.875px,357.38621px) scale(1.71939,1.71939);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 50% {transform: translate(191.875px,357.38621px) scale(1.759032,1.759032);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 65% {transform: translate(191.875px,357.38621px) scale(1.164758,1.164758);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 70% {transform: translate(191.875px,357.38621px) scale(1.716316,1.716316);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 75% {transform: translate(191.875px,357.38621px) scale(1.349638,1.349638);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 80% {transform: translate(191.875px,357.38621px) scale(1.71939,1.71939)} 100% {transform: translate(191.875px,357.38621px) scale(1.71939,1.71939)}} 
@keyframes ePIq8LZSe0t46_f_p { 0% {fill: #6dfe95;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 50% {fill: #1cf9d7;animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {fill: #6dfe95}} 
@keyframes ePIq8LZSe0t48_f_p { 0% {fill: url(#ePIq8LZSe0t48-fill);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 50% {fill: url(#ePIq8LZSe0t48-fill-g1);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {fill: url(#ePIq8LZSe0t48-fill-g2)}}
*/

/* Events Icon Animation
.swiper-slide-active #e6foXkY5Osy4_to {animation: e6foXkY5Osy4_to__to 2000ms linear infinite normal forwards}
.swiper-slide-active #e6foXkY5Osy31_to {animation: e6foXkY5Osy31_to__to 2000ms linear infinite normal forwards}
.swiper-slide-active #e6foXkY5Osy36_to {animation: e6foXkY5Osy36_to__to 2000ms linear infinite normal forwards}
.swiper-slide-active #e6foXkY5Osy41_ts {animation: e6foXkY5Osy41_ts__ts 2000ms linear infinite normal forwards}
.swiper-slide-active #e6foXkY5Osy47_to {animation: e6foXkY5Osy47_to__to 2000ms linear infinite normal forwards}
.swiper-slide-active #e6foXkY5Osy47_ts {animation: e6foXkY5Osy47_ts__ts 2000ms linear infinite normal forwards}
.swiper-slide-active #e6foXkY5Osy48_ts {animation: e6foXkY5Osy48_ts__ts 2000ms linear infinite normal forwards}

@keyframes e6foXkY5Osy4_to__to { 0% {transform: translate(448.6501px,297px)} 100% {transform: translate(-0.8499px,297px)}} 
@keyframes e6foXkY5Osy31_to__to { 0% {transform: translate(-17.516307px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {transform: translate(236.60134px,315.546004px)}} 
@keyframes e6foXkY5Osy36_to__to { 0% {transform: translate(-21.516307px,315.546004px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 100% {transform: translate(232.60134px,315.546004px)}} 
@keyframes e6foXkY5Osy41_ts__ts { 0% {transform: translate(191.875px,357.38621px) scale(1.759032,1.759032);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 15% {transform: translate(191.875px,357.38621px) scale(1.164758,1.164758);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 20% {transform: translate(191.875px,357.38621px) scale(1.716316,1.716316);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 25% {transform: translate(191.875px,357.38621px) scale(1.349638,1.349638);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 30% {transform: translate(191.875px,357.38621px) scale(1.71939,1.71939);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 50% {transform: translate(191.875px,357.38621px) scale(1.759032,1.759032);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 65% {transform: translate(191.875px,357.38621px) scale(1.164758,1.164758);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 70% {transform: translate(191.875px,357.38621px) scale(1.716316,1.716316);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 75% {transform: translate(191.875px,357.38621px) scale(1.349638,1.349638);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 80% {transform: translate(191.875px,357.38621px) scale(1.71939,1.71939)} 100% {transform: translate(191.875px,357.38621px) scale(1.71939,1.71939)}}
@keyframes e6foXkY5Osy47_to__to { 0% {transform: translate(198.880662px,217.718691px);animation-timing-function: cubic-bezier(0.785,0.135,0.15,0.86)} 50% {transform: translate(198.880662px,211.718691px);animation-timing-function: cubic-bezier(0.785,0.135,0.15,0.86)} 100% {transform: translate(198.880662px,217.718691px)}} 
@keyframes e6foXkY5Osy47_ts__ts { 0% {transform: scale(1.546344,1.546344);animation-timing-function: cubic-bezier(0.785,0.135,0.15,0.86)} 50% {transform: scale(1.789746,1.789746);animation-timing-function: cubic-bezier(0.785,0.135,0.15,0.86)} 100% {transform: scale(1.546344,1.546344)}} 
@keyframes e6foXkY5Osy48_ts__ts { 0% {transform: translate(198.880661px,250.189029px) scale(1.91445,1.914449);animation-timing-function: cubic-bezier(0.785,0.135,0.15,0.86)} 50% {transform: translate(198.880661px,250.189029px) scale(1.81272,1.812719);animation-timing-function: cubic-bezier(0.785,0.135,0.15,0.86)} 100% {transform: translate(198.880661px,250.189029px) scale(1.91445,1.914449)}}
*/

/* Community Icon Animation */

.swiper-slide-active #eywLR7oEDFi5_to {
  animation: eywLR7oEDFi5_to__to 1600ms linear infinite normal forwards;
}
.swiper-slide-active #eywLR7oEDFi9 {
  animation: eywLR7oEDFi9_c_o 1600ms linear infinite normal forwards;
}
.swiper-slide-active #eywLR7oEDFi10 {
  animation: eywLR7oEDFi10_c_o 1600ms linear infinite normal forwards;
}
.swiper-slide-active #eywLR7oEDFi11 {
  animation: eywLR7oEDFi11_c_o 1600ms linear infinite normal forwards;
}
.swiper-slide-active #eywLR7oEDFi12 {
  animation: eywLR7oEDFi12_c_o 1600ms linear infinite normal forwards;
}
.swiper-slide-active #eywLR7oEDFi15 {
  animation: eywLR7oEDFi15_c_o 1600ms linear infinite normal forwards;
}
.swiper-slide-active #eywLR7oEDFi16 {
  animation: eywLR7oEDFi16_c_o 1600ms linear infinite normal forwards;
}
.swiper-slide-active #eywLR7oEDFi17 {
  animation: eywLR7oEDFi17_c_o 1600ms linear infinite normal forwards;
}
.swiper-slide-active #eywLR7oEDFi18 {
  animation: eywLR7oEDFi18_c_o 1600ms linear infinite normal forwards;
}

@keyframes eywLR7oEDFi5_to__to {
  0% {
    transform: translate(448.6501px, 297px);
  }
  100% {
    transform: translate(89.0501px, 297px);
  }
}
@keyframes eywLR7oEDFi9_c_o {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  37.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  62.5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes eywLR7oEDFi10_c_o {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  37.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  62.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes eywLR7oEDFi11_c_o {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  12.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  87.5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes eywLR7oEDFi12_c_o {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  12.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  87.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    opacity: 1;
  }
}
@keyframes eywLR7oEDFi15_c_o {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  37.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  62.5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes eywLR7oEDFi16_c_o {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  37.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  62.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes eywLR7oEDFi17_c_o {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  12.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  25% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  75% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  87.5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes eywLR7oEDFi18_c_o {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  12.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  87.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    opacity: 1;
  }
}

#eNAcER0rQyY1 {
  border-radius: 18px;
}
.swiper-slide-active #eNAcER0rQyY7 {
  animation-name: eNAcER0rQyY7-down;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  opacity: 1;
}

.swiper-slide-active #eNAcER0rQyY3 {
  animation-name: eNAcER0rQyY3-up;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  opacity: 1;
}

.button {
  padding: 8px 22px !important;
  border: 1px solid #ffffff !important;
  background: linear-gradient(
    355.67deg,
    #f63523 -18.37%,
    #7d0b00 -18.36%,
    #55006e 100.08%
  );
  border-radius: 40px !important;
  color: #fff !important;
  line-height: 1.5 !important;
  width: fit-content;
}

.js-scorll-x-auto img,
.js-scorll-x-auto a {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.other-side-container .bottom-text {
  background: linear-gradient(180deg, #6dfe95 0%, #1cf9d7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
/* Terms Css on Signup */

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.terms .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.terms .checkmark {
  position: absolute;
  top: 10px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.terms .container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.terms .container input:checked ~ .checkmark {
  background-color: #6dfe95;
}

/* Create the checkmark/indicator (hidden when not checked) */
.terms .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.terms .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.terms .container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Privacy Policy */
.privacy-policy p {
  margin-bottom: 10px;
}

.privacy-policy span {
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 10px;
}

.privacy-policy table,
.privacy-policy tr,
.privacy-policy td {
  border: 1px solid #fff;
}

.privacy-policy td {
  padding: 3px;
}

.terms-conditions p {
  margin-bottom: 10px;
}

a {
  color: white;
}
