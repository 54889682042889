.bkg-gradient {
  z-index: 1;
  position: fixed;
  overflow-y: scroll;
}

.tile {
  filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.3));
}

.play-icon {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.slider-rail {
  background: linear-gradient(270deg, #fd7735 70%, #ba0270);
}

.slider-track {
  background: linear-gradient(90deg, #ba0270 70%, #fd7735);
}

.slider-thumb {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: linear-gradient(180deg, #6dfe95 0%, #1cf9d7 100%);
}

.section-header-bkg {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 160px;
}

.play-button-locked {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background: linear-gradient(180deg, #6dfe95 0%, #1cf9d7 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-waveform {
  mix-blend-mode: overlay;
}

.tile-image-container {
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;

  @media (min-width: 1024px) {
    width: 300px;
    height: 300px;
  }
}

.tile-image-container img {
  width: 100%;
  height: auto;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: white;
  mix-blend-mode: overlay;
  -webkit-mix-blend-mode: overlay;
}
