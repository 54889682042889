.transmission-item {
  border: 1px solid #6efe96;
  border-radius: 4px;
  background-color: #3c001b60;
  color: white;
}

.transmission-item:hover,
.transmission-item:active {
  border: none;
  background: linear-gradient(180deg, #6dfe95 0%, #6dfe95 50%, #1cf9d7 100%);
  color: black;
}

.transmission-body {
  border: 1px solid #6efe96;
  border-radius: 4px;
  background-color: #3c001b60;
  color: white;
}
