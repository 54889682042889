.skeleton {
  mix-blend-mode: overlay;
  mask-image: linear-gradient(
    115deg,
    rgba(255, 255, 255, 100),
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 100) 100%
  );
  background-color: white;
  mask-position: 100%;
  mask-size: 300% 100%;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    mask-position: 0%;
  }
}
